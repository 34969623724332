import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const Gdpr = loadable(() => import("@components/GDPR/Gdpr"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const GdprPage = ({ path }) => (
    <>
        <SEO
            title="Impressit GDPR Compliance"
            titleTemplate="Impressit"
            description="Impressit is a GDPR-friendly company. You have the right to determine what you allow us to collect. Please check this section for more details."
            path={path}
        />
        <Layout path={path}>
            <Gdpr />
        </Layout>
    </>
);

export default GdprPage;
